<template>
  <div class="grid">
    <div class="col-12 lg:col-4">
      <Card>
        <template #title>Шифрование файлов</template>
        <template #content>
          <div class="p-fluid grid">
            <div class="field col-12">
              <label>Бюро</label>
              <Dropdown
                :disabled="loading"
                :options="$store.state.compendiums.cryptoTaskBureaus"
                :option-label="'value'"
                :option-value="'code'"
                v-model="bureau"
              ></Dropdown>
            </div>
            <div class="field col-12" v-if="bureau !== null">
              <FileUpload
                :disabled="loading"
                mode="basic"
                choose-label="Выберите файл"
                name="crypt[]"
                @upload="onUpload($event)"
                @before-upload="beforeUpload"
                @error="onErrorUpload"
                url="/crypto-upload"
              ></FileUpload>
            </div>
          </div>
          <ProgressBar v-if="loading" mode="indeterminate"></ProgressBar>
        </template>
      </Card>
    </div>
    <div class="col-12 lg:col-8">
      <Card>
        <template #title>Список зашифрованных файлов</template>
        <template #content>
          <AbstractTable ref="dataTable" code="cryptoTasks" display-filter="menu" expander>
            <template #additionalButtons>
              <Button
                icon="pi pi-refresh"
                class="p-button-rounded p-button-outlined"
                @click="$refs.dataTable.getData()"
              ></Button>
            </template>
            <template #bureau="row">
              {{ $filters.translate($store, 'cryptoTaskBureaus', row.data.bureau) }}
            </template>
            <template #status="row">
              {{ $filters.translate($store, 'cryptoTaskStatuses', row.data.status) }}
            </template>
            <template #expand="row">
              <CryptoTask :id="row.row.data.id"></CryptoTask>
            </template>
          </AbstractTable>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable.vue";
import CryptoTask from "@/components/crypt/CryptoTask.vue";

export default {
  name: "Crypt",
  components: {CryptoTask, AbstractTable},
  data() {
    return {
      loading: false,
      bureau: null,
    }
  },
  methods: {
    onErrorUpload() {
      this.$toast.add({
        severity: 'error',
        summary: 'Ошибка загрузки файла',
        detail: 'Произошла ошибка загрузки файла для подписи и шифрования',
        life: 5000
      })
      this.loading = false
      this.bureau = null
    },
    onUpload() {
      this.$toast.add({
        severity: 'success',
        summary: 'Загрузка файла завершена',
        detail: 'Файл загружен. Через некоторое время он будет подписан и зашифрован. Результат можно будет увидеть в таблице.',
        life: 5000
      })
      this.loading = false
      this.bureau = null
      this.$refs.dataTable.getData()
    },
    beforeUpload(evt) {
      this.loading = true
      evt.formData.append('bureau', this.bureau)
    }
  }
}
</script>

<style scoped>

</style>