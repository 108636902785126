<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title>Задание шифрования №{{ id }}</template>
        <template #content>
          <div class="col-12">
            <div v-if="loading">
              <ProgressSpinner></ProgressSpinner>
            </div>
            <div v-if="!loading">
              <p><strong>ID: </strong> {{ task.id }}</p>
              <p><strong>Бюро: </strong> {{ $filters.translate($store, 'cryptoTaskBureaus', task.bureau) }} </p>
              <p><strong>Имя файла: </strong> {{ task.filename }} </p>
              <p><strong>Файлы скачиваются в виде архива! Для отправки в Бюро надо их сначала разархивировать!</strong></p>
              <p v-for="f in task.files" :key="f.id">
                  <Button
                    icon="pi pi-download"
                    v-if="f.fileId !== null"
                    :label="'Скачать ' + this.$filters.translate($store, 'cryptoTaskFileTypes', f.type).toLowerCase() + ' файл'"
                    @click="download(f.id)"
                  ></Button>
              </p>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import CRUDService from "@/service/CRUDService";

export default {
  name: "CryptoTask",
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      task: null,
    }
  },
  methods: {
    getTask() {
      CRUDService.get('crypto_task', this.id).then((res) => {
        this.task = res
        this.loading = false
      })
    },
    download(id) {
      window.open('/download/crypto_task_file/' + id, '_blank')
    }
  },
  mounted() {
    this.getTask()
  }
}
</script>

<style scoped>

</style>